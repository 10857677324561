import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';




const Hint4 = () => (
  <Layout>
    <Header /> 

    <header className="masthead5">
    <br></br>
    <br></br>
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Hint #4</h1>
          <br></br>
          <br></br>
          <br></br>
          <h2 className="tracking-in-expand mx-auto mt-2 mb-5">
            {/* {config.subHeading} */}
          Upon me you can carry 
          <br></br>
          but I am not very wide.
          <br></br>
          <br></br>
          An engine with wheels
          <br></br>
          you do sit astride.
          <br></br>
          <br></br>
          They come as a single
          <br></br>
          possibly a double.
          <br></br>
          <br></br>
          But when they arrive
          <br></br>
          their noise is not very subtle. 
          <br></br>
          </h2> 
          <h3 className="roll-in-bottom mx-auto">
          Distance: 1.1 miles North</h3>
          <br></br>
          <br></br>
          <br></br>
          <a href="/clue4" className="btn btn-primary">
              Back to Clue
            </a>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </div>
      </div>
    </header>

    {/* <Clues />  

    <SocialLinks />
    <Footer /> */}
  </Layout>
);

export default Hint4;
